import React from "react"
import styled from "styled-components"
import { faEnvelope, faFax, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Container } from "react-bootstrap"
import { Link } from "gatsby"

import variables from "../../constants/variables"

import OutlineButton from "../OutlineButton"

const AboutMe = () => {
  return (
    <StyledInfo id="info">
      <Container className="py-5">
        <div className="d-flex flex-column flex-lg-row justify-content-around">
          <div className="col-12 col-lg-9 content-box my-auto">
            <h6>Über uns</h6>
            <p>
              Ganz unabhängig von der Größe Ihres Unternehmens unterstützt Sie
              Ihr externer Büroservice im Arbeitsalltag und bietet Ihnen
              kaufmännische Hilfe, damit Sie mehr Zeit für das Kerngeschäft
              haben. Ob kurz- oder langfristige Unterstützung. Office EBE ist
              gerne für Sie da und sorgt für mehr Effizienz in Ihrem Geschäft.
            </p>
            <Link to="/#performances">
              <OutlineButton title="Zu den Leistungen"></OutlineButton>
            </Link>
          </div>
          <div className="d-none d-lg-block col-sm-3 ml-sm-5 mt-5 mt-sm-0">
            <h6>Erreichbar unter</h6>
            <p>
              Telefon: <a href="tel:+498092551188">08092 / 255 11 88</a>
              <br />
              Fax: <span className="fax">08092 / 255 70 83</span>
              <br />
              Email: <a href="mailto:info@office-ebe.de">info@office-ebe.de</a>
            </p>
          </div>
          <div className="d-lg-none col-12 mt-5">
            <h6>Erreichbar unter</h6>
            <a
              type="button"
              className="button text-center"
              href="tel:+498092551188"
            >
              <FontAwesomeIcon size="xs" icon={faPhone} /> 08092 / 255 11 88
            </a>
            <br />
            <button type="button" className="button text-center">
              <FontAwesomeIcon size="xs" icon={faFax} /> 08092 / 255 70 83
            </button>
            <br />
            <a
              type="button"
              className="button text-center"
              href="mailto:info@office-ebe.de"
            >
              <FontAwesomeIcon size="xs" icon={faEnvelope} /> info@office-ebe.de
            </a>
          </div>
        </div>
      </Container>
    </StyledInfo>
  )
}

const StyledInfo = styled.div`
  a {
    color: ${variables.COLORS.primaryColor};
    &:hover {
      text-decoration: none;
      font-weight: bold;
    }
  }
  .fax {
    color: ${variables.COLORS.primaryColor};
  }
  button {
    color: ${variables.COLORS.primaryColor};
    background-color: unset;
  }
  .button {
    padding: 10px;
    border: 1px solid ${variables.COLORS.primaryColor};
    min-width: 100%;
    margin: 5px 0;
    border-radius: 5px;
  }
`

export default AboutMe
