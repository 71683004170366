import React from "react"

import StyledHero from "../StyledHero"
import Banner from "../Banner"

const Header = () => {
  return (
    <StyledHero home>
      <Banner title="Office EBE" subtitle="Ihr externer Büroservice" />
    </StyledHero>
  )
}

export default Header
