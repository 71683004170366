import React from "react"

import "../utils/smothScroll"
import "../utils/resizer"

import Layout from "../components/layout"
import SEO from "../components/SEO"

import Header from "../components/Intro/Header"
import Info from "../components/Info/Info"
import Performances from "../components/Performances/Performances"
import Contact from "../components/Contact/Contact"

export default ({ path, location }) => (
  <Layout path={path} location={location}>
    <SEO title="Home" keywords={[`Office EBE, Büroservice`]} />
    <Header />
    <Info />
    <Performances />
    <Contact />
  </Layout>
)
