import React from "react"
import { CardDeck, Row } from "react-bootstrap"

import PerformanceCard from "../../components/Performances/PerformanceCard"

const PerformanceGroup = ({ group, xs = 1, sm = 1, md = 1, lg = 3, perf }) => {
  return (
    <CardDeck className="mb-5">
      <Row xs={xs} sm={sm} md={md} lg={lg}>
        {group.map((card, idx) => {
          return (
            <PerformanceCard
              key={idx}
              image={card.image}
              alt={card.alt}
              title={card.title}
              text={card.text}
              perf={perf}
            />
          )
        })}
      </Row>
    </CardDeck>
  )
}

export default PerformanceGroup
