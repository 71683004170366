import React from "react"
import { Container, Form } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import StyledHero from "../StyledHero"
import OutlineButton from "../OutlineButton"

const getBackground = graphql`
  query {
    contact: file(relativePath: { eq: "contact.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Contact = () => {
  const image = useStaticQuery(getBackground)
  return (
    <section id="contact">
      <StyledHero img={image.contact.childImageSharp.fluid} home>
        <Container>
          <StyledForm
            action="https://formspree.io/f/xgerqnez"
            method="POST"
            className="my-5"
          >
            <Form.Group>
              <Form.Control
                required
                type="text"
                name="vorname"
                id="vorname"
                className=""
                placeholder="Vorname"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                required
                type="text"
                name="nachname"
                id="nachname"
                className=""
                placeholder="Nachname"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                required
                type="email"
                name="email"
                id="email"
                className=""
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="tele"
                id="tele"
                className=""
                placeholder="Telefonnummer"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                required
                as="textarea"
                rows={3}
                name="message"
                id="message"
                placeholder="Ihre Nachricht"
              />
            </Form.Group>
            <OutlineButton title="Abschicken" white />
          </StyledForm>
        </Container>
      </StyledHero>
    </section>
  )
}

const StyledForm = styled(Form)`
  background: rgba(0, 0, 0, 0.4);
  padding: 40px;
  border-radius: 20px;

  /* IE fix */
  @media all and (-ms-high-contrast: none) {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }
`

export default Contact
