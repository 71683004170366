import React from "react"
import Image from "gatsby-image"
import { Col, Container, Row } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import { isBrowser } from "react-device-detect"
import styled from "styled-components"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import {
  faEuroSign,
  faArrowsAlt,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import PerformanceGroup from "../../components/Performances/PerformanceGroup"
import {
  performance1,
  performance2,
  performance3,
  performance4,
  performance5,
  performance6,
  performance7,
} from "./content"

import StyledHero from "../StyledHero"
import Title from "../Title"

const getImages = graphql`
  query {
    performance: file(relativePath: { eq: "performance-banner.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    working: file(relativePath: { eq: "performance-work.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Performances = () => {
  const images = useStaticQuery(getImages)
  const cardGroups = [
    {
      cardgroup1: [
        {
          text: performance1,
        },
        {
          text: performance2,
        },
        {
          text: performance3,
        },
        {
          text: performance4,
        },
        {
          text: performance5,
        },
        {
          text: performance6,
        },
        {
          text: performance7,
        },
      ],
      cardgroup3: [
        {
          text: performance7,
        },
      ],
      cardgroup4: [
        {
          title: <FontAwesomeIcon color="green" size="lg" icon={faClock} />,
          text: "Zeitersparnis",
        },
        {
          title: <FontAwesomeIcon color="green" size="lg" icon={faEuroSign} />,
          text: "Kostenersparnis",
        },
        {
          title: <FontAwesomeIcon color="green" size="lg" icon={faArrowsAlt} />,
          text: "Flexibilität",
        },
        {
          title: <FontAwesomeIcon color="green" size="lg" icon={faHeadset} />,
          text: "Betreuung",
        },
      ],
      cardgroup5: [
        {
          title: <FontAwesomeIcon color="green" size="lg" icon={faArrowsAlt} />,
          text: "Flexibilität",
        },
        {
          title: <FontAwesomeIcon color="green" size="lg" icon={faHeadset} />,
          text: "Betreuung",
        },
      ],
    },
  ]

  const getImage = () => {
    if (isBrowser) {
      return images.performance.childImageSharp.fluid
    } else {
      return images.performance.childImageSharp.fluid
    }
  }

  return (
    <StyledSection id="performances">
      <div className="hero-container">
        <StyledHero img={getImage()} />
        <div className="centered d-lg-none">
          <Title mobile title="Unsere Leistungen" />
        </div>
      </div>
      <Container className="py-5">
        <Row className="d-none d-lg-flex">
          <Col className="mb-5 mt-lg-5" lg={6}>
            <Image
              className="rounded"
              fluid={images.working.childImageSharp.fluid}
              alt="Mitarbeiter im Büro"
            />
          </Col>
          <Col className="mb-5 mt-lg-5 align-self-center" lg={6}>
            <div className="d-none d-lg-block">
              <Title title="Unsere Leistungen" />
              <hr />
            </div>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. In
              fermentum et sollicitudin ac orci phasellus. Nunc pulvinar sapien
              et ligula ullamcorper malesuada. Sed euismod nisi porta lorem
              mollis aliquam ut. Feugiat scelerisque varius morbi enim nunc
              faucibus a pellentesque. Donec pretium vulputate sapien nec
              sagittis aliquam malesuada bibendum. Amet tellus cras adipiscing
              enim eu turpis egestas. Faucibus turpis in eu mi bibendum. Velit
              laoreet id donec ultrices tincidunt arcu non. Pulvinar etiam non
              quam lacus suspendisse faucibus interdum posuere. Nibh venenatis
              cras sed felis eget velit aliquet sagittis. Eget gravida cum
              sociis natoque penatibus et magnis. Risus viverra adipiscing at in
              tellus integer.
            </p> */}
          </Col>
        </Row>

        <PerformanceGroup group={cardGroups[0].cardgroup1} perf />

        <Row className="mt-5 align-items-center">
          <Col lg={6}>
            <Title title="Ihre Vorteile" />
            <hr />
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. In
            fermentum et sollicitudin ac orci phasellus. Nunc pulvinar sapien et
            ligula ullamcorper malesuada. Sed euismod nisi porta lorem mollis
            aliquam ut. Feugiat scelerisque varius morbi enim nunc faucibus a
            pellentesque. Donec pretium vulputate sapien nec sagittis aliquam
            malesuada bibendum. Amet tellus cras adipiscing enim eu turpis
            egestas. Faucibus turpis in eu mi bibendum. Velit laoreet id donec
            ultrices tincidunt arcu non. Pulvinar etiam non quam lacus
            suspendisse faucibus interdum posuere. Nibh venenatis cras sed felis
            eget velit aliquet sagittis. Eget gravida cum sociis natoque
            penatibus et magnis. Risus viverra adipiscing at in tellus integer. */}
          </Col>
          <Col lg={6}>
            <PerformanceGroup group={cardGroups[0].cardgroup4} lg={2} />
          </Col>
        </Row>
      </Container>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .hero-container {
    position: relative;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default Performances
