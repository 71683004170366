import React from "react"
import { Card, Col } from "react-bootstrap"

import styled from "styled-components"
import variables from "../../constants/variables"

const PerformanceCard = ({ title, text, perf }) => {
  return (
    <Col className="mt-1 mt-md-5">
      <StyledCard className="text-center card" perf={perf}>
        <Card.Body>
          {title && <Card.Title>{title}</Card.Title>}
          <Card.Text>{text}</Card.Text>
        </Card.Body>
      </StyledCard>
    </Col>
  )
}

const StyledCard = styled.div`
  border-radius: 10px;
  box-shadow: ${variables.SHADOWS.lightShadow};
  p {
    margin-bottom: 0;
    font-weight: 500;
  }
  .card-body {
    min-height: 112px;
    align-items: ${props => (props.perf ? "center" : "unset")};
    display: ${props => (props.perf ? "flex" : "block")};
    justify-content: ${props => (props.perf ? "center" : "unset")};
  }
`

export default PerformanceCard
