import React from "react"

export const performance1 = (
  <p>
    Vorbereitung der Buchhaltungsunterlagen und diverser Dokumente für den
    Steuerberater
  </p>
)

export const performance2 = (
  <p>
    Rechnungsprüfung sowie Rechnungsstellung inkl. Zahlungsverfolgung und
    Mahnwesen
  </p>
)

export const performance3 = <p>Allgemeine Korrespondenz und Sachbearbeitung</p>

export const performance4 = <p> Verwaltung innerbetriblicher Verträge</p>

export const performance5 = "Angebotserstellung"

export const performance6 = <p>Sortieren und Ablage von Dokumenten</p>

export const performance7 = (
  <p>Entgegennahme, Weiterleitung und ggf. Bearbeitung von Post</p>
)
